body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

nav {
  grid-row: 1;
  background: white;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto auto auto 1fr auto;
  align-items: center;
  justify-items: center;
  padding: 4pt 8pt;
  grid-gap: 10pt;

  a {
    color: #333333;
    text-decoration: none;
  }

  .navbar-brand {
    font-weight: bold;
    font-size: 14pt;
  }

  .active a {
    border-bottom: 2pt solid black;
  }
}

#mainHolder {
  position: relative;
  grid-row: 2;
  overflow: auto;
  height: 100%;

  &.no-scroll {
    overflow: hidden;
  }

  .container {
    max-width: 800px;
  }

  .stripe-homepage {
    display: grid;
    align-content: center;
    justify-content: center;
    color: black;
    font-weight: 900;
    height: calc(100vh - 81px);
    background: url("/assets/images/main/hanger.jpg") no-repeat center center;
    background-size: cover;

    .content {
      position: relative;
      padding:0;
      border: 10px solid rgba(255, 255, 255, 0.7);

      h1 {
        padding: 10px;
        margin:0;
        background:white;
      }
    }
  }

  .modal-body .underlined {
    padding-left:10px;
    padding-right:10px;
  }

  .partners {
    margin-top: 40px;
    h3 {
      margin: 0;
    }
    .logos {
      display: inline-block;
      width: 16%;
      margin: 0 4% 0 12%;
      vertical-align: top;
    }

    .nameCopy {
      display: inline-block;
      width: 60%;
      margin: 0 0 30px;
    }
  }
}

footer {
  grid-row: 3;
  background: black;
  display: grid;
  grid-gap: 6pt;
  padding: 3pt 6pt;
  grid-template-columns: 1fr auto 1fr auto;

  a {
    color: white;
    text-decoration: none;
  }
}

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  display: grid;
  align-content: center;
  justify-content: center;

  &.hide {
    display: none;
  }

  .modal-dialog {
    background: white;
    border: 1px solid black;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 6pt;

    .modal-header {
      grid-row: 1;
      border-bottom: 1px solid black;

      h2 {
        margin: 4pt;
        text-align: center;
      }
    }

    .modal-body {
      grid-row:2;
      padding: 12pt;
    }

    .modal-footer {
      grid-row: 3;

      .btn {
        cursor: pointer;
        padding: 4pt;
        width: 100%;
        border-radius: 0;
        outline: none;
        border: none;
        background: black;
        color: white;
      }
    }
  }
}